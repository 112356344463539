import React from 'react'
import { graphql, Link } from 'gatsby'
import Img from 'gatsby-image'
import styled from 'styled-components'
import { animated, useSpring, config } from 'react-spring'
import Layout, { MyPBox } from '../components/layout'
import GridItem from '../components/grid-item'
import SEO from '../components/SEO'
import { ChildImageSharp } from '../types'
import { AnimatedBox } from '../elements'

type PageProps = {
  data: {
    research: ChildImageSharp
    threeProjects: {
      edges: {
        node: {
          ChildImageSharp
        }
      }[]
    }
    aboutUs: ChildImageSharp
  }
}

const cfg = require('../../config')

const Area = styled(animated.div)`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 25vw 25vw 25vw;
  grid-template-areas:
    'welcome-text welcome-text welcome-text'
    'box-research about-us about-us'
    'three-projects three-projects three-projects';

  @media (max-width: ${props => props.theme.breakpoints[3]}) {
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: 35vw 30vw 30vw 25vw;

    grid-template-areas:
      'welcome-text welcome-text welcome-text welcome-text'
      'box-research box-research about-us about-us'
      'three-projects three-projects three-projects three-projects'
      'three-projects three-projects three-projects three-projects';
  }

  @media (max-width: ${props => props.theme.breakpoints[1]}) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(5, 38vw);

    grid-template-areas:
      'welcome-text welcome-text'
      'box-research about-us'
      'three-projects three-projects'
      'three-projects three-projects'
      'three-projects three-projects';
  }

  @media (max-width: ${props => props.theme.breakpoints[0]}) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr repeat(5, 50vw);

    grid-template-areas:
      'welcome-text'
      'box-research'
      'about-us'
      'three-projects'
      'three-projects'
      'three-projects';
  }
`

const BoxResearch = styled(GridItem)`
  grid-area: box-research;
`

const AboutUs = styled(GridItem)`
  grid-area: about-us;
`

const ThreeProjects = styled.div`
  grid-area: three-projects;
  display: grid;
  grid-template-columns: repeat(3, 1fr);

  @media (max-width: ${props => props.theme.breakpoints[1]}) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr;
  }
`

const WelcomeText = styled.div`
  grid-area: welcome-text;
`

const Index: React.FunctionComponent<PageProps> = ({ data: { research, threeProjects, aboutUs } }) => {
  const pageAnimation = useSpring({
    config: config.slow,
    from: { opacity: 0 },
    to: { opacity: 1 },
  })

  return (
    <Layout color={cfg.backgroundColor}>
      <SEO />
      <Area style={pageAnimation}>
        <WelcomeText>
          <AnimatedBox style={pageAnimation} px={[6, 6, 8, 6, 8]}>
            <h1>Welcome!</h1>
            <p>
              Hi! My name is Florian Blum and I live in{' '}
              <a href="https://en.wikipedia.org/wiki/Mülheim">Mülheim an der Ruhr</a> in Germany.
            </p>
            <p>
              After studying applied computer science at the{' '}
              <a href="https://www.uni-due.de">University of Duisburg-Essen</a> I'm currently working at the{' '}
              <a href="https://se.wiwi.uni-due.de">chair of Prof. Dr. Volker Gruhn</a> where I do my PhD in the area of
              Blockchain-oriented Software Engineering and Blockchain Software Architectures.
            </p>
            <p>
              Interested in discussing anything related to Blockchain technology?{' '}
              <Link to="/about" aria-label="Visit about page">
                Feel free
              </Link>{' '}
              to send me an email, call me or drop by my office for a coffee! ;)
            </p>
          </AnimatedBox>
        </WelcomeText>
        <BoxResearch to="/research" aria-label="Visit research page">
          <Img fluid={research.childImageSharp.fluid} />
          <span>Research</span>
        </BoxResearch>
        <AboutUs to="/about" aria-label="Visit about page">
          <Img fluid={aboutUs.childImageSharp.fluid} />
          <span>About</span>
        </AboutUs>
        <ThreeProjects>
          {threeProjects.edges.map(({ node: project }, index) => (
            <GridItem to="/photos" key={project.childImageSharp.fluid.src} aria-label="View photos page">
              <Img fluid={project.childImageSharp.fluid} />
              {index == 0 && <span>Photos</span>}
            </GridItem>
          ))}
        </ThreeProjects>
      </Area>
    </Layout>
  )
}

export default Index

export const query = graphql`
  query IndexQuery {
    research: file(sourceInstanceName: { eq: "images" }, name: { eq: "hybrid" }) {
      childImageSharp {
        fluid(quality: 80, maxWidth: 1200) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    aboutUs: file(sourceInstanceName: { eq: "images" }, name: { eq: "photo-cornwall" }) {
      childImageSharp {
        fluid(quality: 80, maxWidth: 1200) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    threeProjects: allFile(
      filter: { name: { in: ["photo-sunrise-iceland", "photo-petri-kirche-luebeck", "photo-train-station-muelheim"] } }
    ) {
      edges {
        node {
          childImageSharp {
            fluid(quality: 80, maxWidth: 1200) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`
